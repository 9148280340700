import "./App.css";
import { Container, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import Web3 from "web3";
import abi from "./abi.json";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";

function App() {
  const [web3, setWeb3] = useState("");
  const [add, setAdd] = useState("");
  const [instance, setInstance] = useState(null);
  const [mintNumber, setMintNumber] = useState(1);
  const [totalMinted, setTotalMinted] = useState(null);

  useEffect(() => {
    let web3 = new Web3(Web3.givenProvider);
    setWeb3(web3);

    const ins = new web3.eth.Contract(
      abi,
      "0x5Aec9E4d6ca8A1515c7cd4ED7AC89bec70BcC3E0"
    );

    setInstance(ins);

    if (window.ethereum) {
      const getTotalMinted = async () => {
        var tokenCount = await ins.methods.tokenCount().call();
        console.log(tokenCount);
        setTotalMinted(tokenCount);
      };
      getTotalMinted();
    }
  }, []);

  async function requestAccount() {
    console.log("Requesting account...");

    // ❌ Check if Meta Mask Extension exists
    if (window.ethereum) {
      console.log("detected");

      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAdd(accounts[0]);
      } catch (error) {
        console.log("Error connecting...");
      }
    } else {
      window.open("https://metamask.app.link/dapp/grokpunks.xyz/");
      alert("Meta Mask not detected");
    }
  }

  const mint = async () => {
    if (add) {
      if (instance) {
        const addressMint = await instance.methods.balanceOf(add).call();
        if (addressMint < 1) {
          console.log(
            await instance.methods.mint(mintNumber).send({
              from: add,
              value: (
                (await instance.methods.cost().call()) *
                (mintNumber - 1)
              ).toString(),
            })
          );
        } else {
          console.log(
            await instance.methods.mint(mintNumber).send({
              from: add,
              value: (
                (await instance.methods.cost().call()) * mintNumber
              ).toString(),
            })
          );
        }
      } else {
        console.log("error");
      }
    } else {
      alert("Please Connect your wallet");
    }
  };

  const plus = () => {
    if (mintNumber < 10) setMintNumber(mintNumber + 1);
  };
  const minus = () => {
    if (mintNumber > 1) {
      setMintNumber(mintNumber - 1);
    }
  };

  return (
    <div className="mint-page">
      <Container>
        <Row>
          <Col
            className=" d-flex align-items-center justify-content-center"
            lg="6"
          >
            <div className="">
              <img
                className="img-fluid logo"
                src={require("./assets/imgs/GROKTITLE.png").default}
                alt=""
              />
            </div>
          </Col>
          <Col
            className=" d-flex align-items-center justify-content-center"
            lg="6"
          >
            <div className="">
              <div className=" text-center rmt">
                <div onClick={requestAccount} className="btn cusbtn">
                  <span className="font-weight-bold text-uppercase">
                    {add ? add.substring(0, 15) : "Connect Wallet"}
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col lg="6">
            <div className="p-5">
              <img
                style={{ borderRadius: "10px" }}
                className="img-fluid"
                src={require("./assets/imgs/GROKPUNKS.gif").default}
                alt=""
              />
            </div>
          </Col>
          <Col
            className=" d-flex align-items-center justify-content-center"
            lg="6"
          >
            <div className="">
              <div className=" text-center rmt">
                <div className="">
                  <p className="font-weight-bold">
                    Immerse yourself in a world where artificial intelligence
                    and artistic expression collide. Grok Punks NFT brings you a
                    unique collection of digital assets that capture the essence
                    of Elon Musk's Grok AI and pay homage to the trailblazing
                    Cryptopunks.
                  </p>
                  <p>Mint Price: 0.005 &amp; 1 Free</p>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-3">
                  <div className="mx-1">
                    <AiFillMinusCircle onClick={minus} size={60} />
                  </div>
                  <div className="display-number d-flex justify-content-center align-items-center">
                    <span className="number">{mintNumber}</span>
                  </div>
                  <div className="mx-1">
                    <AiFillPlusCircle onClick={plus} size={60} />
                  </div>
                </div>
                <div className="text-center mt-5">
                  <button
                    onClick={mint}
                    className="mintbutton btn  text-uppercase"
                  >
                    Mint Now
                  </button>
                </div>
                <div className="text-center mt-4">
                  <h3 className="font-weight-bold bfont">
                    {/* {} */}
                    {totalMinted} / 3000
                  </h3>
                </div>
                <div className="text-center mt-4">
                  <a
                    href="https://twitter.com/GrokPunks"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      style={{ borderRadius: "10px" }}
                      className="img-fluid si"
                      src={require("./assets/imgs/Twitter.png").default}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://opensea.io/collection/grokpunks"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      style={{ borderRadius: "10px" }}
                      className="img-fluid si"
                      src={require("./assets/imgs/Opensea.png").default}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://etherscan.io/address/0x5aec9e4d6ca8a1515c7cd4ed7ac89bec70bcc3e0"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      style={{ borderRadius: "10px" }}
                      className="img-fluid si"
                      src={require("./assets/imgs/scan.png").default}
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
